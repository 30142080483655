import { useEffect, useState } from "react";
import { Controller, FormProvider } from "react-hook-form";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { TalentDetailsDto } from "../../api/models/talent";
import { useTalent } from "../../api/talent";
import ApiError from "../../api/common/apiError";
import ConfirmDialogBox from "../ConfirmDialogBox";
import FormTextInput from "../FormTextInput";
import useConfirmDialogBoxState from "../ConfirmDialogBox/useConfirmDialogBoxState";
import { useAppPaths } from "../../Routes";
import FormErrors from "../FormErrors";
import { useNavigate } from "react-router-dom";
import FormCard from "../FormCard";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { TalentEditFormValues, useEditTalentForm } from "./TalentEditFormValues";
import { useOrganizationConfig } from "../../api/organization";
import FormInputLabel from "../FormInputLabel";
import { talentTooltips } from "../../tooltipsContent";
import FormRadioInput from "../FormRadioInput";
import FormTextAreaInput from "../FormTextAreaInput";
import FormDateInput from "../FormDateInput";
import {
    ReusableDropdown as RatePackDropdown,
    ReusableDropdown as CompetencyLevelDropdown,
    ReusableDropdown as WheelPositionDropdown,
    ReusableDropdown as DocumentationLanguageDropdown
} from "../ReusableDropdown";
import { fetchRatePackOptions } from "../../api/ratePacks";
import { fetchCompetencyLevelOptions } from "../../api/competencyLevel";
import { fetchDocumentationLanguageOptions } from "../../api/languages";
import { fetchWheelPositionOptions } from "../../api/wheelPositions";
import { TalentAgencyFormDropdown } from "./TalentAgencyFormDropdown";
import useAccessToken from "../../auth/useAccessToken";
import { FormDropdownOption } from "../FormDropdown";
import { fetchLocationOptions, fetchLocalities } from "../../api/location";
import FormAutocomplete from "../FormAutocomplete";
import useSearchLocalities from "../../utils/useSearchLocalities";
import ToggleButton from "../ToggleButton";

export type TalentEditFormProps = {
    onSubmit: (values: TalentEditFormValues) => Promise<GigApiFetcherResponse<void>>
    initialValues: TalentDetailsDto
    isSubmitting: boolean
}

const TalentEditForm = ({
    initialValues,
    onSubmit,
    isSubmitting,
}: TalentEditFormProps) => {
    const accessToken = useAccessToken();
    const appPaths = useAppPaths();
    const theme = useTheme();
    const navigate = useNavigate();
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();
    const { talent } = useTalent(initialValues.id);
    const { talentTerminology, organizationConfig } = useOrganizationConfig();
    const [isTalentPersonalInformationLoaded, setIsTalentPersonalInformationLoaded] = useState<boolean>(false);

    const methods = useEditTalentForm({
        ...initialValues,
        agencyId: organizationConfig?.isTalentAgencyEnabled ? initialValues?.agency?.id ?? "None" : null,
        organizationAvailability: initialValues.organizationAvailability ?? "",
        isQualiopiRequired: initialValues.isQualiopiRequired ? "true" : "false",
        vendorName: initialValues.vendorName ?? "",
        presentationStyle: initialValues.presentationStyle ?? "",
        insuranceExpiryDate: initialValues.insuranceExpiryDate ?? null,
        contractSentDate: initialValues.contractSentDate ?? null,
        ratePackId: initialValues.ratePack?.id ?? "",
        competencyLevelId: initialValues.competencyLevel?.id ?? "",
        wheelPositionId: initialValues.wheelPosition?.id ?? null,
        documentationLanguageId: initialValues.documentationLanguage?.id ?? null,
        knownCompetitors: initialValues.knownCompetitors ?? "",
        rlc: initialValues.rlc ?? "",
        notes: initialValues.notes ?? "",
        professionalBackground: initialValues.professionalBackground ?? "",
        locationId: initialValues.location?.countryId || "",
        localityId: initialValues.location?.locality?.localityId || "",
        isHidden: initialValues.isHidden
    });

    const { formState: { isDirty } } = methods;
    const [openCancelDialog, cancelDialogState] = useConfirmDialogBoxState({
        onConfirm: () => {
            navigate(appPaths.talents.index);
        }
    });

    useEffect(() => {
        if (isTalentPersonalInformationLoaded) return;

        methods.reset({
            ...initialValues,
            agencyId: organizationConfig?.isTalentAgencyEnabled ? initialValues?.agency?.id ?? "None" : null,
            organizationAvailability: initialValues.organizationAvailability ?? "",
            isQualiopiRequired: initialValues.isQualiopiRequired ? "true" : "false",
            vendorName: initialValues.vendorName ?? "",
            presentationStyle: initialValues.presentationStyle ?? "",
            ratePackId: initialValues.ratePack?.id ?? "",
            competencyLevelId: initialValues.competencyLevel?.id ?? "",
            wheelPositionId: initialValues.wheelPosition?.id ?? null,
            documentationLanguageId: initialValues.documentationLanguage?.id ?? null,
            knownCompetitors: initialValues.knownCompetitors ?? "",
            rlc: initialValues.rlc ?? "",
            notes: initialValues.notes ?? "",
            professionalBackground: initialValues.professionalBackground ?? "",
            locationId: initialValues?.location?.countryId || "",
            localityId: initialValues?.location?.locality?.localityId || "",
            isHidden: initialValues.isHidden
        });

        if (initialValues) {
            setIsTalentPersonalInformationLoaded(true);
        }
    }, [initialValues, methods, isTalentPersonalInformationLoaded]);

    const [selectedLocationLocalities, setSelectedLocationLocalities] = useState<FormDropdownOption<string>[]>([]);
    const [allLocationOptions, setAllLocationOptions] = useState<FormDropdownOption<string>[]>([]);
    const [, setIsLoadingLocationOptions] = useState(false);
    const selectedLocationId = methods.watch("locationId") || "";
    useEffect(function resetLocalityOnLocationChange() {
        if (!isTalentPersonalInformationLoaded || initialValues?.location?.countryId === selectedLocationId) return;
        methods.setValue("localityId", "");
    }, [selectedLocationId]);

    const handleFormSubmit = async (values: TalentEditFormValues) => {
        setSubmissionError(undefined);
        const response = await onSubmit({
            ...values,
            agencyId: !organizationConfig?.isTalentAgencyEnabled || values.agencyId === "None" ? null : values.agencyId,
            organizationAvailability: values.organizationAvailability === "" ? null : values.organizationAvailability,
            vendorName: values.vendorName === "" ? null : values.vendorName,
            presentationStyle: values.presentationStyle === "" ? null : values.presentationStyle,
            knownCompetitors: values.knownCompetitors === "" ? null : values.knownCompetitors,
            rlc: values.rlc === "" ? null : values.rlc,
            notes: values.notes === "" ? null : values.notes,
            professionalBackground: values.professionalBackground === "" ? null : values.professionalBackground,
            isQualiopiRequired: values.isQualiopiRequired === "true" ? "true" : "false",
            isHidden: values.isHidden ? values.isHidden : false
        });

        if (response.success) {
            methods.reset({
                ...values,
                agencyId: organizationConfig?.isTalentAgencyEnabled ? values?.agencyId ?? "None" : null,
                isQualiopiRequired: values.isQualiopiRequired === "true" ? "true" : "false",
                locationId: values?.locationId || "",
                localityId: values?.localityId || ""
            });
        } else {
            setSubmissionError(response.error);
        }
    };

    const handleCloseButton = () => {
        if (isDirty) {
            openCancelDialog();
        } else {
            navigate(appPaths.talents.index);
        }
    };

    useEffect(() => {
        if (!initialValues) return;
        methods.reset({
            ...initialValues,
            isQualiopiRequired: initialValues.isQualiopiRequired ? "true" : "false",
            locationId: initialValues.location?.countryId || "",
            localityId: initialValues.location?.locality?.localityId || ""
        });
        if (initialValues?.location?.locality) {
            const localities = [
                {
                    value: initialValues.location.locality.localityId,
                    label: `${initialValues.location.locality.locality}${
                        initialValues.location.locality.region ? ` (${initialValues.location.locality.region})` : ""
                    }`,
                },
            ];
            setSelectedLocationLocalities(localities);
        }
    }, [initialValues, methods.reset]);

    useEffect(() => {
        const locationId = methods.watch("locationId");
    
        if (locationId && locationId !== initialValues?.location?.countryId) {
            fetchAndSetLocalities(locationId);
        }
    }, [methods.watch("locationId"), accessToken, initialValues]);

    const fetchAndSetLocalities = (locationId: string) => {
        fetchLocalities(locationId, accessToken, "", 0, 2500)
            .then((response) => {
                const localities = response.items.map((locality) => ({
                    value: locality.localityId,
                    label: `${locality.locality}${locality.region ? ` (${locality.region})` : ""}`,
                }));
                setSelectedLocationLocalities(localities);
                methods.setValue("localityId", "");
            })
            .catch((error) => {
                console.error("Error fetching localities:", error);
            });
    };

    useEffect(() => {
        const selectedLocationId = methods.watch("locationId");
        if (!selectedLocationId) {
            methods.setValue("localityId", ""); // Clear locality when no location selected
        }
    }, [methods.watch("locationId")]);

    useEffect(() => {
        if (accessToken) {
            const loadAllLocations = async () => {
                setIsLoadingLocationOptions(true);
                try {
                    const fetchedLocationOptions = await fetchLocationOptions("", accessToken);
                    setAllLocationOptions(fetchedLocationOptions as FormDropdownOption<string>[]);
                } finally {
                    setIsLoadingLocationOptions(false);
                }
            };
            loadAllLocations();
        }
    }, [accessToken]);

    const {
        localities,
        handleSearchChange,
    } = useSearchLocalities(fetchLocalities, selectedLocationId, accessToken);
    
    useEffect(() => {
        if (localities.length > 0) {
            const updatedLocalities = localities.map((locality) => ({
                value: locality.localityId,
                label: `${locality.locality}${locality.region ? ` (${locality.region})` : ""}`,
            }));
            setSelectedLocationLocalities(updatedLocalities);
        }
    }, [localities]);

    return <>
        <FormProvider {...methods}>
            <form
                onSubmit={methods.handleSubmit(handleFormSubmit)}
                noValidate
            >
                <FormCard
                    onClose={handleCloseButton}
                    withoutTitleUnderline={true}
                    width="100%"
                    title={talent?.firstName + " " + talent?.lastName}>
                    <Box sx={{
                        "> *:not(:last-child)": {
                            marginBottom: theme.spacing(2),
                        }
                    }}>
                        <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                            ID: <Typography component="span">{initialValues.id}</Typography>
                        </Typography>
                        <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                            Email: <Typography component="span"><a className="hover:underline" href={`mailto:${initialValues.email}`}>{initialValues.email}</a></Typography>
                        </Typography>
                        <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                            Phone number: <Typography component="span">{initialValues.phoneNumber || "Not specified"}</Typography>
                        </Typography>
                        <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                            City: <Typography component="span">{`${talent?.location?.locality.locality} ${talent?.location?.locality.region ? ` (${talent?.location?.locality.region})` : ""}`}</Typography>
                        </Typography>
                        <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                            Country: <Typography component="span">{`${initialValues.location?.country}` || "Not specified"}</Typography>
                        </Typography>
                        <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                            Completed hours: <Typography component="span">{initialValues.gigCompletedHours}</Typography>
                        </Typography>
                        <Divider />
                        <Box className="space-y-4">
                            {organizationConfig?.isTalentAvailabilityEnabled && (
                                <Box className="space-y-2">
                                    <FormInputLabel tooltipText={talentTooltips.talentOrganizationAvailability(talentTerminology)}>{`${talentTerminology} availability`}</FormInputLabel>
                                    <FormTextInput name="organizationAvailability" />
                                </Box>
                            )}
                            {organizationConfig?.isTalentAgencyEnabled && (
                                <TalentAgencyFormDropdown
                                    methods={methods}
                                    isSubmitting={isSubmitting}
                                />
                            )}
                        </Box>
                        <FormRadioInput
                            name="isQualiopiRequired"
                            label="Is Qualiopi Required?"
                            disabled={isSubmitting}
                            options={[
                                {
                                    value: "true",
                                    label: "Yes",
                                },
                                {
                                    value: "false",
                                    label: "No",
                                },
                            ]}
                        />
                        <Controller
                            name="isHidden"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <ToggleButton
                                    label={"Do not match"}
                                    onChange={onChange}
                                    checked={value}
                                    tooltip={talentTooltips.hideAC(talentTerminology)}
                                />
                            )}
                        />
                        <Box className="space-y-2">
                            <FormInputLabel>Vendor name</FormInputLabel>
                            <FormTextInput name="vendorName" placeholder="Add vendor name" />
                        </Box>
                        <FormTextAreaInput
                            name="presentationStyle"
                            label="Presentation style"
                            placeholder={`Add ${talentTerminology}s presentation style`}
                            sx={{ marginBottom: theme.spacing(2) }}
                        />
                        <Controller
                            name="contractSentDate"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <FormDateInput
                                    name="contractSentDate"
                                    label="Contract sent date"
                                    value={value || null}
                                    onChange={onChange}
                                    error={methods.formState.errors.contractSentDate}
                                />
                            )}
                        />
                        <Controller
                            name="insuranceExpiryDate"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <FormDateInput
                                    name="insuranceExpiryDate"
                                    label="Insurance expiry date"
                                    value={value || null}
                                    onChange={onChange}
                                    error={methods.formState.errors.insuranceExpiryDate}
                                />
                            )}
                        />
                        <Controller
                            name="ratePackId"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <RatePackDropdown
                                    required
                                    error={methods.formState.errors.ratePackId}
                                    onChange={onChange}
                                    value={value || ""}
                                    label="Select rate pack"
                                    fetchOptions={fetchRatePackOptions}
                                />
                            )}
                        />
                        <Controller
                            name="competencyLevelId"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <CompetencyLevelDropdown
                                    required
                                    error={methods.formState.errors.competencyLevelId}
                                    onChange={onChange}
                                    value={value || ""}
                                    label="Select competency level"
                                    fetchOptions={fetchCompetencyLevelOptions}
                                />
                            )}
                        />
                        <Controller
                            name="wheelPositionId"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <WheelPositionDropdown
                                    error={methods.formState.errors.wheelPositionId}
                                    onChange={onChange}
                                    value={value || ""}
                                    label="Wheel position"
                                    fetchOptions={fetchWheelPositionOptions}
                                />
                            )}
                        />
                        <Controller
                            name="documentationLanguageId"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <DocumentationLanguageDropdown
                                    error={methods.formState.errors.documentationLanguageId}
                                    onChange={onChange}
                                    value={value || ""}
                                    label="Documentation language"
                                    fetchOptions={fetchDocumentationLanguageOptions}
                                />
                            )}
                        />
                        <div className="md:flex space-y-4 md:space-x-6 md:space-y-0">
                            {allLocationOptions.length > 0 && (
                                <><div className="w-full md:w-1/2">
                                    <Controller
                                        name="locationId"
                                        control={methods.control}
                                        render={({ field: { onChange, value } }) => (
                                            <FormAutocomplete
                                                required
                                                label="Country"
                                                value={value || ""}
                                                options={allLocationOptions}
                                                onChange={onChange}
                                                error={methods.formState.errors.locationId}
                                                disabled={allLocationOptions.length < 1} />
                                        )} />
                                </div>
                                </>
                            )}

                            {selectedLocationLocalities.length > 0 && (
                                <><div className="w-full md:w-1/2">
                                    <Controller
                                        name="localityId"
                                        control={methods.control}
                                        render={({ field: { onChange } }) => (
                                            <FormAutocomplete
                                                required
                                                label="City"
                                                value={methods.watch("localityId") || ""}
                                                options={selectedLocationLocalities}
                                                onChange={(newValue, newOption) => {
                                                    onChange(newValue, newOption);
                                                }}
                                                onInputChange={(event) => {
                                                    handleSearchChange(event || ""); // Update localities based on input change
                                                }}
                                                error={methods.formState.errors.localityId}
                                                disabled={selectedLocationLocalities.length < 1} />
                                        )} />
                                </div>
                                </>
                            )}
                        </div>
                        <Box className="space-y-4">
                            <Box className="space-y-2">
                                <FormInputLabel>{"Known competitors"}</FormInputLabel>
                                <FormTextInput name="knownCompetitors" placeholder="Add any known competitors" />
                            </Box>
                            <Box className="space-y-2">
                                <FormInputLabel>{"RLC"}</FormInputLabel>
                                <FormTextInput name="rlc" placeholder={`Add this ${talentTerminology}s main RLC`} />
                            </Box>
                            <FormTextAreaInput
                                name="notes"
                                label="Notes"
                                placeholder="Add any relevant notes"
                                sx={{ marginBottom: theme.spacing(2) }}
                                minRows={3}
                            />
                            <FormTextAreaInput
                                name="professionalBackground"
                                label="Professional Background"
                                placeholder="Add something about their background"
                                sx={{ marginBottom: theme.spacing(2) }}
                                minRows={3}
                            />
                        </Box>

                        <FormErrors messages={submissionError?.userMessages} />

                        <Box className="flex justify-end !mt-8">
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                startIcon={<SaveOutlinedIcon sx={!isDirty || isSubmitting ? { opacity: "26%" } : { opacity: "100%" }} />}
                                loading={isSubmitting}
                                disabled={!isDirty}
                                className="w-full md:w-auto"
                            >
                                Save changes
                            </LoadingButton>
                        </Box>
                    </Box>
                </FormCard>
            </form>
        </FormProvider >
        <ConfirmDialogBox
            {...cancelDialogState}
            message="Discard unsaved changes?"
            confirmButtonText="Discard"
        />
    </>;
};

export default TalentEditForm;