import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ApiError from "../api/common/apiError";
import { useGig } from "../api/gig";
import { gigStatuses } from "../api/models/gig";
import { useOrganizationConfig } from "../api/organization";
import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import { useAppPaths, useGigId } from "../Routes";
import { successToast } from "../toast";
import { GigEditFormValues } from "../components/GigEdit/GigEditFormValues";
import { GigEditForm } from "../components/GigEdit";
import { GigViewPage } from "../components/GigView";

export type GigStatus = keyof typeof gigStatuses;

const GigPage = () => {
    const [showEditGig, setShowEditGig] = useState<boolean>(false);
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();
    const gigId = useGigId();
    const { gig, updateGig, isUpdatingGig, isLoading } = useGig(gigId);
    const appPaths = useAppPaths();
    const navigate = useNavigate();
    const { gigTerminology } = useOrganizationConfig();

    useEffect(() => {
        if (!showEditGig) navigate(appPaths.gigs.view(gigId));
    }, []);

    const handleSubmit = async (values: GigEditFormValues, postAfterSaving: boolean) => {
        if (values.startDate === null) throw ("Expected start date not to be null.");

        const response = await updateGig({
            ...values,
            title: values.title,
            description: values.description || null,
            expectedDurationId: values.expectedDurationId,
            hasClientHistory: values.hasClientHistory,
            expectedNumberParticipants: values.expectedNumberParticipants,
            startDate: values.startDate,
            skillIds: values.skills.map(_ => _.id),
            gigTaggedUserIds: values.gigTaggedUsers.map(_ => _.userId),
            gigStatusId: values.gigStatusId,
            postAfterSaving: postAfterSaving,
            opportunityLink: values.opportunityLink || null,
            notes: values.notes || null,
            address: values.address || null
        });

        if (response.success) {
            navigate(appPaths.gigs.view(gigId));
            setShowEditGig(false);
            successToast(`${gigTerminology} successfully updated.`);
        }
        
        return response;
    };

    const handleSaveChanges = async (values: GigEditFormValues) => {
        setSubmissionError(undefined);

        const response = await handleSubmit(values, true);

        if (!response.success) setSubmissionError(response.error);
    };

    const handleCloseButton = () => {
        navigate(appPaths.gigs.index);
    };

    const handleEditGig = () => {
        navigate(appPaths.gigs.edit(gigId));
        setShowEditGig(true);
    };

    const handleCloseEditGigPage = () => {
        setShowEditGig(false);
    };

    if (!gig || isLoading) return <Loader />;

    return (
        <FormContainer>
            {showEditGig ? (
                <GigEditForm
                    gig={gig}
                    handleSaveChanges={handleSaveChanges}
                    handleCloseEditGigPage={handleCloseEditGigPage}
                    loading={isUpdatingGig || isLoading}
                    submissionError={submissionError}
                />
            ) : (
                <GigViewPage
                    gig={gig}
                    handleCloseButton={handleCloseButton}
                    handleEditGig={handleEditGig}
                    loading={isUpdatingGig || isLoading}
                />
            )}
        </FormContainer >
    );
};

export default GigPage;