import { useEffect, useState, useRef } from "react";

type Locality = {
    localityId: string;
    locality: string;
    region?: string;
    ianaId?: string;
};

type FetchLocalitiesType = (
    locationId: string,
    accessToken: string | null,
    searchTerm: string,
    pageIndex: number,
    pageSize: number
) => Promise<{
    items: Locality[];
}>;

const useSearchLocalities = (
    fetchLocalities: FetchLocalitiesType,
    locationId: string,
    accessToken: string | null
) => {
    const [searchTerm, setSearchTerm] = useState<string>(""); // Current input
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>(""); // Debounced value
    const [localities, setLocalities] = useState<Locality[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

    const handleSearchChange = (newSearchTerm: string) => {
        setSearchTerm(newSearchTerm.trim());
    
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
    
        debounceTimeout.current = setTimeout(() => {
            setDebouncedSearchTerm(newSearchTerm.trim());
        }, 1500);
    };

    useEffect(() => {
        if (debouncedSearchTerm.length >= 1) {
            setIsLoading(true);
            fetchLocalities(locationId, accessToken, debouncedSearchTerm, 0, 2500)
                .then((response) => setLocalities(response.items || []))
                .catch((error) => console.error("Error fetching localities:", error))
                .finally(() => setIsLoading(false));
        } else {
            setLocalities([]);
        }
    }, [debouncedSearchTerm, locationId, accessToken]);       

    useEffect(() => {
        return () => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
        };
    }, []);

    return { searchTerm, debouncedSearchTerm, localities, isLoading, handleSearchChange };
};

export default useSearchLocalities;
