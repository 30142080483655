import gigApiFetcher from "./common/fetching";
import { ClientUser } from "./models/client";

export const fetchClientUsers = async (
    giggedClientId: string,
    accessToken: string | null,
): Promise<ClientUser[]> => {
    const path = `api/gigged-clients/${giggedClientId}/users`;
    const response = await gigApiFetcher<never, []>({
        path: path,
        token: accessToken || undefined
    });

    return response;
};

export const fetchClientUserOptions = async (giggedClientId: string, accessToken: string | null) => {
    const response = await fetchClientUsers(giggedClientId, accessToken);

    return response
        .map(data => ({
            value: data.userId,
            label: data.userName,
        }));
};

export const fetchClientUsersCount = async (giggedClientId: string, accessToken: string | null) => {
    const response = await gigApiFetcher<never, number>({
        path: `api/gigged-clients/${giggedClientId}/users`,
        method: "GET",
        body: undefined,
        token: accessToken || undefined,
    });

    return response;
};
