import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useOrganizationConfig } from "../../api/organization";

export type GigCreateFormValues = {
    title: string
    expectedDurationId: number
    opportunityLink?: string | null
    industryId: string
    description?: string | null
    startDate: Date | null
    skills: { id: string, name: string }[],
    gigTaggedUsers: { userId: string, userName: string }[],
    hasClientHistory: boolean
    expectedNumberParticipants: number
    gigStatusId: number,
    giggedClientId: string
    isAudienceExecutive: boolean
    notes?: string | null
    locationId: string | null
    localityId: string | null
    languageId: string
    deliveryTypeId: number
    isDesignFeeTypeRequired: boolean
    designFeeTypeHours?: number
    isConsultancyFeeTypeRequired: boolean
    consultancyFeeTypeHours?: number
    isPrepAndEvalFeeTypeRequired: boolean
    prepAndEvalFeeTypeHours?: number
    address?: string | null
};

export const useGigCreateForm = () => {
    const schema = gigValidationSchema();

    return useForm<GigCreateFormValues>({
        resolver: yupResolver(schema),
        mode: "onSubmit",
        defaultValues: {
            industryId: "",
            expectedNumberParticipants: 0,
            startDate: null, 
            expectedDurationId: 0,
            hasClientHistory: false,
            isAudienceExecutive: false,
            locationId: "",
            localityId: "",
            languageId: "",
            isDesignFeeTypeRequired: false,
            isConsultancyFeeTypeRequired: false,
            isPrepAndEvalFeeTypeRequired: false,
        }
    });
};

const gigValidationSchema = () => {
    const { giggedClientTerminology } = useOrganizationConfig();

    return yup
        .object({
            title: yup.string().required("Client name is required."),
            description: yup.string().optional().nullable(),
            expectedNumberParticipants: yup
                .number()
                .typeError("Expected number of participants must be a valid number.")
                .required("Expected number of participants is required.")
                .min(0, "Expected number of participants must be at least 0.")
                .max(1000, "Expected number of participants must be at most 1000."),
            expectedDurationId: yup.number().required().min(0, "Duration is required."),
            industryId: yup.string().required("Please select Industry"),
            opportunityLink: yup.string().optional().nullable(),
            startDate: yup.date()
                .required("Start date and time is required.")
                .min(new Date(), "Start date must be later than today.")
                .nullable()
                .typeError("Start date must be a valid date and time."),
            skills: yup
                .array()
                .length(1, "You must select a workshop type.")
                .of(
                    yup.object().shape({
                        id: yup.string(),
                        name: yup.string(),
                    }),
                ),
            gigTaggedUsers: yup
                .array()
                .min(1, "Number of tagged users must be 1 or greater.")
                .of(
                    yup.object().shape({
                        userId: yup.string(),
                        userName: yup.string(),
                    }),
                ),
            hasClientHistory: yup.boolean().required("This field is required."),
            isAudienceExecutive: yup.boolean().required("This field is required."),
            giggedClientId: yup.string().required(`${giggedClientTerminology} is required.`),
            notes: yup.string().optional().nullable().max(2500, "Notes cannot exceed 2500 characters."),
            locationId: yup.string().required("Country is required."),
            localityId: yup.string().required("City is required."),
            languageId: yup.string().required("Please select a language."),
            deliveryTypeId: yup.number().required("Delivery format is required.").min(0, "Delivery format is required."),
            isDesignFeeTypeRequired: yup.boolean().required("This field is required."),
            designFeeTypeHours: yup.number().when("isDesignFeeTypeRequired", {
                is: true,
                then: yup.number()
                    .typeError("Number of hours is required.")
                    .moreThan(0, "Number of hours must be 1 or greater.")
                    .lessThan(101, "Number of hours must not exceed 100.")
                    .required("Number of hours is required"),
                otherwise: yup.number().optional().nullable(),
            }),
            isConsultancyFeeTypeRequired: yup.boolean().required("This field is required."),
            consultancyFeeTypeHours: yup.number().when("isConsultancyFeeTypeRequired", {
                is: true,
                then: yup.number()
                    .typeError("Number of hours is required.")
                    .moreThan(0, "Number of hours must be 1 or greater.")
                    .lessThan(101, "Number of hours must not exceed 100.")
                    .required("Number of hours is required"), 
                otherwise: yup.number().optional().nullable(),
            }),
            isPrepAndEvalFeeTypeRequired: yup.boolean().required("This field is required."),
            prepAndEvalFeeTypeHours: yup.number().when("isPrepAndEvalFeeTypeRequired", {
                is: true,
                then: yup.number()
                    .typeError("Number of hours is required.")
                    .moreThan(0, "Number of hours must be 1 or greater.")
                    .lessThan(101, "Number of hours must not exceed 100.")
                    .required("Number of hours is required"),
                otherwise: yup.number().optional().nullable(),
            }),
            address: yup.string().optional().nullable(),
        })
        .required();
};