import gigApiFetcher from "./common/fetching";
import { LocalityDto, LocationDto } from "../api/models/api/location";
import { GigApiPaginatedData } from "./common/apiResponse";

export const fetchLocations = async (query = "", accessToken: string | null): Promise<LocationDto[]> => {
    const response = await gigApiFetcher<never, LocationDto[]>({
        path: `api/locations?searchTerm=${query}`,
        method: "GET",
        body: undefined,
        token: accessToken || undefined,
    });

    return response;
};

export const fetchLocationOptions = async (query: string, accessToken: string | null) => {
    const locationsResponse = await fetchLocations(query, accessToken);

    return locationsResponse
        .map(location => ({
            value: location.id,
            label: location.country,
        }));
};

export const fetchLocalities = async (locationId: string, accessToken: string | null, searchTerm: string, pageIndex: number, pageSize: number) => {
    const queryParams = new URLSearchParams({
        searchTerm: searchTerm, 
        locationId: locationId, 
        pageIndex: pageIndex.toString(), 
        pageSize: pageSize.toString()
    });
    const response = await gigApiFetcher<never, GigApiPaginatedData<LocalityDto>>({
        path: `api/locations/localities?${queryParams.toString()}`,
        method: "GET",
        body: undefined,
        token: accessToken || undefined,
    });
    
    return response;
};
