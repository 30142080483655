import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { Box, FormHelperText, SxProps, Theme, TextareaAutosize} from "@mui/material";
import FormInputLabel from "./FormInputLabel";
import clsx from "clsx";

export type FormTextAreaInputProps = {
  name: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  rows?: number;
  minRows?: number;
  maxRows?: number;
  sx?: SxProps<Theme>;
  placeholder?: string;
  isResizeable?: boolean;
  tooltipText?: string;
};

const FormTextAreaInput = React.forwardRef<HTMLTextAreaElement, FormTextAreaInputProps>(({
    name,
    label,
    disabled,
    required,
    rows,
    minRows = 5,
    maxRows = 5,
    sx,
    placeholder,
    isResizeable = false,
    tooltipText,
    ...inputProps
}, ref) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const errorMessage = errors[name] ? errors[name].message : "";

    return (
        <Box className="space-y-2" sx={{ ...sx, minWidth: "100%" }}>
            {label && (
                <FormInputLabel htmlFor={name} required={required} tooltipText={tooltipText}>{label}</FormInputLabel>
            )}
            <Controller
                name={name}
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <TextareaAutosize
                        {...field}
                        {...inputProps}
                        minRows={minRows || rows}
                        maxRows={maxRows}
                        disabled={disabled}
                        aria-label={label}
                        placeholder={placeholder}
                        className={clsx(
                            "border border-black rounded-xl w-full p-4",
                            "focus:outline-none focus:ring-1 focus:ring-black focus:border-black",
                            errors[name] && "outline-error border-error outline outline-2 pr-10 focus:ring-error",
                            isResizeable && "resize-y"
                        )}
                        ref={ref}
                    />
                )}
            />
            {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
        </Box>
    );
});

export default FormTextAreaInput;