import { FieldError } from "react-hook-form";
import { FormSearchDropdownOption } from "./FormSearchDropdown/FormSearchDropdown";
import FormInputLabel from "./FormInputLabel";
import { Chip } from "@mui/material";
import GiggedClientUserDropdown from "./GiggedClientUserDropdown";
import { useEffect } from "react";

export type GigTaggedUserInputProps = {
    addTaggedUser: (user: { userId: string, userName: string }) => void
    removeTaggedUser: (index: number) => void
    gigTaggedUsers: { userId: string, userName: string }[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: any
    showError?: boolean
    loading?: boolean
    required?: boolean
    giggedClientId: string
}

const GigTaggedUserInput = ({
    addTaggedUser,
    removeTaggedUser,
    gigTaggedUsers,
    error,
    showError,
    loading,
    required,
    giggedClientId
}:GigTaggedUserInputProps) => {

    useEffect(() => {
        if (gigTaggedUsers.length > 0) {
            // Remove all existing tagged users
            gigTaggedUsers.forEach((_, index) => {
                removeTaggedUser(index);
            });
        }
    }, [giggedClientId]);

    const handleSelectTagged = (_: string, option: FormSearchDropdownOption | FormSearchDropdownOption[] | null) => {
        if (option === null) return;
        const optionsArray = Array.isArray(option) ? option : [option];
        optionsArray.forEach(item => {
            addTaggedUser({
                userId: item.value,
                userName: item.label
            });

            if (!gigTaggedUsers) {
                gigTaggedUsers = [];
            }
            if (gigTaggedUsers.length <= 1) {
                gigTaggedUsers.push({
                    userId: item.value,
                    userName: item.label
                });
            }
        });
    };

    const handleRemoveTagged = (index: number) => {
        removeTaggedUser(index);
    };

    const fieldError: FieldError | undefined = (error)?.message ? error as FieldError : undefined;
    if (!gigTaggedUsers) return <>"Loading tagged users..."</>;
    return (
        <div className="space-y-2">
            <FormInputLabel required={required}>Would you like to tag any other users in this request?</FormInputLabel>
            {gigTaggedUsers.length >= 0 &&
                <GiggedClientUserDropdown
                    value={""}
                    onChange={handleSelectTagged}
                    // disabled={gigTaggedUser.length === 1}
                    disabled={!gigTaggedUsers}
                    disabledClientUserIds={gigTaggedUsers.map(_ => _.userId)}
                    error={showError ? fieldError : undefined}
                    giggedClientId={giggedClientId}
                />
            }
            <div className="-mt-2 -mr-2 child:mr-2 child:mt-2 pt-2">
                {gigTaggedUsers.map((taggedData, index) => (
                    <Chip
                        key={taggedData.userId}
                        label={taggedData.userName}
                        onDelete={() => handleRemoveTagged(index)}
                        sx={{ marginRight: 1, marginBottom: 1 }}
                        disabled={loading}
                        variant="outlined"
                        color="secondary"
                    />
                ))}
            </div>
        </div>
    );
};

export default GigTaggedUserInput;