import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import defaultIfUndefined from "../../utils/defaultIfUndefined";
import { ApiClientLocationDto } from "../../api/models/api/location";
import { ApiIndustryDto } from "../../api/models/api/industry";
import { LanguageDto } from "../../api/models/api/language";

export type GigEditFormValues = {
    title: string
    expectedDurationId: number
    industry?: ApiIndustryDto
    industryId: string
    opportunityLink?: string | null
    description?: string | null
    startDate: Date | null
    skills: { id: string, name: string }[],
    gigTaggedUsers: { userId: string, userName: string }[],
    hasClientHistory: boolean
    expectedNumberParticipants: number
    gigStatusId: number,
    isAudienceExecutive: boolean
    notes?: string | null
    location?: ApiClientLocationDto
    locationId: string | null
    localityId: string | null
    language?: LanguageDto
    languageId: string
    deliveryTypeId: number
    isDesignFeeTypeRequired: boolean
    designFeeTypeHours?: number
    isConsultancyFeeTypeRequired: boolean
    consultancyFeeTypeHours?: number
    isPrepAndEvalFeeTypeRequired: boolean
    prepAndEvalFeeTypeHours?: number
    address?: string | null
};

export const useEditGigForm = (initialValues?: GigEditFormValues) => {
    const schema = gigEditValidationSchema();
    
    return useForm<GigEditFormValues>({
        resolver: yupResolver(schema),
        mode: "onTouched",
        defaultValues: {
            title: initialValues?.title || "",
            industryId: initialValues?.industry?.id,
            opportunityLink: initialValues?.opportunityLink,
            expectedDurationId: defaultIfUndefined(initialValues?.expectedDurationId, -1),
            expectedNumberParticipants: initialValues?.expectedNumberParticipants || 0,
            startDate: initialValues?.startDate || null,
            description: initialValues?.description,
            isDesignFeeTypeRequired: initialValues?.isDesignFeeTypeRequired || false,
            designFeeTypeHours: initialValues?.designFeeTypeHours || undefined,
            isConsultancyFeeTypeRequired: initialValues?.isConsultancyFeeTypeRequired || false,
            consultancyFeeTypeHours: initialValues?.consultancyFeeTypeHours || undefined,
            isPrepAndEvalFeeTypeRequired: initialValues?.isPrepAndEvalFeeTypeRequired || false,
            prepAndEvalFeeTypeHours: initialValues?.prepAndEvalFeeTypeHours || undefined,
            skills: initialValues?.skills || [],
            gigTaggedUsers: initialValues?.gigTaggedUsers || [],
            hasClientHistory: initialValues?.hasClientHistory || true,
            isAudienceExecutive: initialValues?.isAudienceExecutive || false,
            notes: initialValues?.notes,
            locationId: initialValues?.location?.countryId,
            localityId: initialValues?.location?.locality.localityId,
            languageId: initialValues?.language?.id,
            deliveryTypeId: defaultIfUndefined(initialValues?.deliveryTypeId, -1),
            address: initialValues?.address,
        },
    });
};

const gigEditValidationSchema = () => {
    return yup
        .object({
            title: yup.string().required("Client name is required."),
            description: yup.string().optional().nullable(),
            industryId: yup.string().required("Please select Industry"),
            opportunityLink: yup.string().optional().nullable(),
            expectedNumberParticipants: yup
                .number()
                .typeError("Expected number of participants must be a valid number.")
                .required("Expected number of participants is required.")
                .min(0, "Expected number of participants must be at least 0.")
                .max(1000, "Expected number of participants must be at most 1000."),
            startDate: yup.date()
                .required("Start date and time is required.")
                .min(new Date(), "Start date must be later than today.")
                .nullable()
                .typeError("Start date must be a valid date and time."),
            skills: yup
                .array()
                .length(1, "You must select a workshop type.")
                .of(
                    yup.object().shape({
                        id: yup.string(),
                        name: yup.string(),
                    }),
                ),
            gigTaggedUsers: yup
                .array()
                .min(1, "Number of tagged users must be 1 or greater.")
                .of(
                    yup.object().shape({
                        userId: yup.string(),
                        userName: yup.string(),
                    }),
                ),
            expectedDurationId: yup.number().required().min(0, "Duration is required."),
            hasClientHistory: yup.boolean().required("This field is required."),
            isAudienceExecutive: yup.boolean().required("This field is required."),
            notes: yup.string().optional().nullable().max(2500, "Notes cannot exceed 2500 characters."),
            locationId: yup.string().required("Country is required."),
            localityId: yup.string().required("City is required."),
            languageId: yup.string().required(),
            deliveryTypeId: yup.number().required().min(0, "Delivery format is required."),
            isDesignFeeTypeRequired: yup.boolean().required("This field is required."),
            designFeeTypeHours: yup.number().when("isDesignFeeTypeRequired", {
                is: true,
                then: yup.number()
                    .typeError("Number of hours is required.")
                    .moreThan(0, "Number of hours must be 1 or greater.")
                    .lessThan(101, "Number of hours must not exceed 100.")
                    .required("Number of hours is required"),
                otherwise: yup.number().optional().nullable(),
            }),
            isConsultancyFeeTypeRequired: yup.boolean().required("This field is required."),
            consultancyFeeTypeHours: yup.number().when("isConsultancyFeeTypeRequired", {
                is: true,
                then: yup.number()
                    .typeError("Number of hours is required.")
                    .moreThan(0, "Number of hours must be 1 or greater.")
                    .lessThan(101, "Number of hours must not exceed 100.")
                    .required("Number of hours is required"), 
                otherwise: yup.number().optional().nullable(),
            }),
            isPrepAndEvalFeeTypeRequired: yup.boolean().required("This field is required."),
            prepAndEvalFeeTypeHours: yup.number().when("isPrepAndEvalFeeTypeRequired", {
                is: true,
                then: yup.number()
                    .typeError("Number of hours is required.")
                    .moreThan(0, "Number of hours must be 1 or greater.")
                    .lessThan(101, "Number of hours must not exceed 100.")
                    .required("Number of hours is required"),
                otherwise: yup.number().optional().nullable(),
            }),
            address: yup.string().optional().nullable(),
        })
        .required();
};