import { useEffect, useState } from "react";
import { FieldError } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";
import useAccessToken from "../auth/useAccessToken";
import { FormSearchDropdown } from "./FormSearchDropdown";
import { FormSearchDropdownOption, LoadOptionsCallback } from "./FormSearchDropdown/FormSearchDropdown";
import { fetchClientUserOptions } from "../api/clientUsers";

export type GiggedClientUserDropdownProps = {
    value: string
    onChange: (value: string, option: FormSearchDropdownOption | null) => void
    disabled?: boolean
    required?: boolean
    error?: FieldError
    disabledClientUserIds?: string[],
    tooltip?: string
    label?: string
    giggedClientId: string
}

const GiggedClientUserDropdown = ({
    value,
    onChange,
    disabled,
    required,
    error,
    disabledClientUserIds = [],
    tooltip,
    label,
    giggedClientId
}: GiggedClientUserDropdownProps) => {
    const accessToken = useAccessToken();
    const [clientUserOptions, setClientUserOptions] = useState<FormSearchDropdownOption[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    // Effect to handle giggedClientId changes
    useEffect(() => {
        setClientUserOptions([]); // Clear options
        
        if (!giggedClientId || !accessToken) return;

        setIsLoading(true);
        
        fetchClientUserOptions(giggedClientId, accessToken)
            .then(options => setClientUserOptions(options))
            .finally(() => {
                setIsLoading(false);
            });
    }, [giggedClientId, accessToken]);

    // Handle search/load of options
    const loadClientUserOptions = async (inputValue: string, callback: LoadOptionsCallback) => {
        if (!giggedClientId || !accessToken) {
            callback([]);
            return;
        }

        setIsLoading(true);
        try {
            const options = await fetchClientUserOptions(giggedClientId, accessToken);
            callback(options);
        } catch (error) {
            console.error("Error loading client user options:", error);
            callback([]);
        } finally {
            setIsLoading(false);
        }
    };

    const debouncedLoadClientUserOptions = useDebouncedCallback(loadClientUserOptions, 700);

    return (
        <FormSearchDropdown
            tooltip={tooltip}
            value={value}
            loadOptions={debouncedLoadClientUserOptions}
            onChange={onChange}
            defaultOptions={clientUserOptions}
            disabled={disabled}
            required={required}
            error={error}
            disabledOptionIds={disabledClientUserIds}
            loading={isLoading}
            label={label}
        />
    );
};

export default GiggedClientUserDropdown;