import { useEffect } from "react";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { IconButton, TableCell } from "@mui/material";
import { Link } from "react-router-dom";

import { useTalents } from "../../api/talents";
import Table from "../Table";
import Loader from "../Loader";
import TableCard from "../TableCard";
import TableChipCell from "../Table/TableChipCell";
import formatDateOnlyString from "../../utils/formatDateString";
import { useAppPaths } from "../../Routes";
import { useOrganizationConfig } from "../../api/organization";
import clsx from "clsx";
import { TableHeaderConfig } from "../Table/Table";
import { GenderType, genderTypeOptions } from "../../api/models/talent";
import SplitChip from "../SplitChip";

export type TalentsTableProps = {
    searchTerm?: string,
    skillIds: string[] | undefined
    badgeIds: string[] | undefined
    competencyLevelIds: string[] | undefined
}

const TalentsTable = ({
    searchTerm,
    skillIds,
    badgeIds,
    competencyLevelIds
}: TalentsTableProps) => {
    const talents = useTalents();
    const { organizationConfig, gigTerminologyPlural } = useOrganizationConfig();
    const setSearchTerm = talents.setSearchTerm;
    const appPaths = useAppPaths();

    useEffect(() => {
        setSearchTerm(searchTerm);
    }, [setSearchTerm, searchTerm]);

    const handleOrderByChange = (property: string) => {
        const isAsc = talents.orderBy === property && talents.sortOrder === "asc";
        talents.setSortOrder(isAsc ? "desc" : "asc");
        talents.setOrderBy(property);
    };

    useEffect(() => {
        talents.setSkillIds(skillIds ?? []);
    }, [talents.setSkillIds, skillIds]);

    useEffect(() => {
        talents.setBadgeIds(badgeIds ?? []);
    }, [talents.setBadgeIds, badgeIds]);

    useEffect(() => {
        talents.setCompetencyLevelIds(competencyLevelIds ?? []);
    }, [talents.setCompetencyLevelIds, competencyLevelIds]);

    if (talents.isLoading) return (
        <Loader />
    );

    const headers: TableHeaderConfig[] = [
        {
            label: "",
            isSortable: false,
        },
        {
            label: "ID",
            isSortable: false,
        },
        {
            key: "FirstName",
            label: "First name",
            isSortable: true,
        },
        {
            key: "LastName",
            label: "Last name",
            isSortable: true,
        },
        {
            key: "TradingName",
            label: "Company name",
            isSortable: true,
        },
        {
            key: "Email",
            label: "Email",
            isSortable: true,
        },
        {
            label: "Phone number",
            isSortable: false,
        },
        {
            label: "Gender",
            isSortable: false,
        },
        {
            label: "Vendor name",
            isSortable: false,
        },
        {
            label: "Insurance expiry date",
            isSortable: false,
        },
        {
            label: "Competency level",
            isSortable: false,
        },
        {
            label: "Documentation Language",
            isSortable: false,
        },
        {
            label: "Wheel Position",
            isSortable: false,
        },
        {
            label: "Qualiopi",
            isSortable: false,
        },
        {
            label: `Ongoing ${gigTerminologyPlural.toLowerCase()}`,
            isSortable: false,
        },
        {
            label: `Completed ${gigTerminologyPlural.toLowerCase()}`,
            isSortable: false,
        },
        {
            label: "Completed hours",
            isSortable: false,
        },
        {
            key: "ProfileHealthScore",
            label: "Profile health score",
            isSortable: true,
        },
        {
            key: "CreatedAt",
            label: "Date registered",
            isSortable: true,
        },
        {
            key: "IsHidden",
            label: "Do not match",
            isSortable: true,
        },
    ];

    if (organizationConfig?.isTalentAgencyEnabled) {
        headers.push(
            {
                label: "Agency name",
                isSortable: false,
            },
        );
    }

    if (organizationConfig?.isTalentAvailabilityEnabled) {
        headers.push(
            {
                label: "Availability",
                isSortable: false
            },
        );
    }

    headers.push(
        // {
        //     label: "View profile",
        //     isSortable: false,
        // },
        {
            label: "",
            isSortable: false
        }
    );

    return (
        <TableCard>
            <Table
                headers={headers}
                data={talents.talents}
                rowHeight="73.5px"
                renderRow={talent => (
                    <>
                        <TableCell>
                            {talent.profileImageThumbnailUrl && (
                                <img
                                    src={talent.profileImageThumbnailUrl}
                                    className="h-10 w-10 object-cover rounded-full shrink-0 min-w-[2.5rem]"
                                />
                            )}
                        </TableCell>
                        <TableCell sx={{ fontFamily: "monospace", whiteSpace: "nowrap" }}>{talent.id}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{talent.firstName}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{talent.lastName}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{talent.tradingName || "-"}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}><a className="hover:underline" href={`mailto:${talent.email}`}>{talent.email}</a></TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}><a className="hover:underline" href={`tel:${talent.phoneNumber}`}>{talent.phoneNumber || "-"}</a></TableCell>
                        <TableChipCell labels={[talent.genderTypeId != null ? genderTypeOptions[talent.genderTypeId as GenderType] : ""]} />
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{talent.vendorName || "-"}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{formatDateOnlyString(talent.insuranceExpiryDate) || "-"}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{talent.competencyLevel?.name || "-"}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{talent.documentationLanguage?.name || "-"}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                            {talent.wheelPosition ? (
                                <SplitChip
                                    primaryValue={talent.wheelPosition.wheelPosition}
                                    primaryBgColor={talent.wheelPosition.primaryBgColor}
                                    primaryTextColor={talent.wheelPosition.primaryTextColor}
                                    secondaryValue={talent.wheelPosition.secondary}
                                    secondaryBgColor={talent.wheelPosition.secondaryBgColor}
                                    secondaryTextColor={talent.wheelPosition.secondaryTextColor}
                                />
                            ) : (
                                "-"
                            )}
                        </TableCell>
                        <TableChipCell labels={[talent.isQualiopiRequired ? "Required" : "Not required"]} />
                        <TableCell className={clsx(talent.ongoingGigsCount === 0 && "!text-[#BBB]")}>{talent.ongoingGigsCount}</TableCell>
                        <TableCell className={clsx(talent.completedGigsCount === 0 && "!text-[#BBB]")}>{talent.completedGigsCount}</TableCell>
                        <TableCell className={clsx(talent.gigCompletedHours === 0 && "!text-[#BBB]")}>{talent.gigCompletedHours}</TableCell>
                        <TableCell>{talent.profileHealthScore}%</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{formatDateOnlyString(talent.createdAt)}</TableCell>
                        {organizationConfig?.isTalentAgencyEnabled && <TableCell sx={{ whiteSpace: "nowrap" }}>{talent.agencyName || "-"}</TableCell>}
                        {organizationConfig?.isTalentAvailabilityEnabled && (
                            talent.organizationAvailability ?
                                <TableCell>{talent.organizationAvailability}</TableCell> :
                                <TableChipCell labels={["Available"]} />
                        )}
                        {/* <TableCell sx={{ textAlign: "left" }}>
                            <a href={`https://${gigDashboardDomain}/find-${talentTerminologyPlural.toLowerCase()}/${talent.id}`} target="_blank">
                                <VisibilityIcon color="secondary" />
                            </a>
                        </TableCell> */}
                        <TableChipCell labels={[talent.isHidden ? "True" : "False"]} />
                        <TableCell sx={{ textAlign: "right" }}>
                            <Link to={appPaths.talents.edit(talent.id)}>
                                <IconButton aria-label="edit" >
                                    <ModeEditOutlineOutlinedIcon color="secondary" />
                                </IconButton>
                            </Link>
                        </TableCell>
                    </>
                )}
                onOrderByChange={handleOrderByChange}
                setPageIndex={talents.setPageIndex}
                setPageSize={talents.setPageSize}
                pageSize={talents.pageSize}
                pageIndex={talents.pageIndex}
                totalCount={talents.totalCount}
                orderBy={talents.orderBy}
                sortOrder={talents.sortOrder}
                isLoading={talents.isLoading}
            />
        </TableCard>
    );
};

export default TalentsTable;
