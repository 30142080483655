import { Autocomplete, TextField } from "@mui/material";
import { FieldError } from "react-hook-form";
import clsx from "clsx";
import { CaretDown } from "phosphor-react";
import FormInputLabel from "./FormInputLabel";
import React from "react";
// Define the types for the Autocomplete options
export type FormAutocompleteOption<TValue extends string | number> = {
    label: string;
    value: TValue;
};

// Define the properties for the FormAutocomplete component
export type FormAutocompleteProps<TOption extends FormAutocompleteOption<string | number>> = {
    options: TOption[];
    label?: string;
    value: TOption["value"] | null; // Allow value to be null
    onChange: (value: TOption["value"] | null, option: TOption | null) => void;
    onInputChange?: (value: string | null, option: TOption | null) => void;
    error?: FieldError | string;
    required?: boolean;
    disabled?: boolean;
    className?: string;
    tooltip?: string;
    isLoadingOptions?: boolean;
};
const styles = {
    marginRight: "9px",
};
const FormAutocomplete = <TOption extends FormAutocompleteOption<string | number>>({
    label,
    options,
    value,
    onChange,
    onInputChange,
    error,
    required,
    disabled,
    className,
    tooltip,
    isLoadingOptions,
}: FormAutocompleteProps<TOption>) => {
    const _disabled = disabled || isLoadingOptions;
    const [hoveredOption, setHoveredOption] = React.useState<TOption | null>(null);
    const selected = value !== null ? options.find(option => option.value === value) : null;
    // Handle change event for Autocomplete (ensures controlled state)
    const handleChange = (event: any, newValue: TOption | null) => {
        onChange(newValue ? newValue.value : null, newValue);
    };

    // Handle input change for search filtering and custom input actions
    const handleInputChange = (event: any, newInputValue: string) => {
        if (onInputChange) {
            onInputChange(newInputValue, null); // Pass `null` as there's no full option yet
        }
    };

    return (
        <div className={clsx("space-y-2", className)}>
            {label && (
                <FormInputLabel required={required} tooltipText={tooltip}>
                    {label}
                </FormInputLabel>
            )}

            <Autocomplete
                value={options.find((option) => option.value === value) || null}
                options={options}
                getOptionLabel={(option) => option.label}
                onChange={handleChange}
                onInputChange={handleInputChange}
                disableClearable={value !== null}
                disablePortal
                isOptionEqualToValue={(option, value) => option.value === value?.value} // Correct matching logic
                popupIcon={<CaretDown
                    style={styles}
                    className={clsx(
                        "h-5 w-5 text-primary-text"
                    )}
                    aria-hidden="true"
                />}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        error={!!error}
                        helperText={error ? (typeof error === "string" ? error : error.message) : ""}
                        disabled={_disabled}
                    />
                )}
                renderOption={(props, option) => {
                    // <li {...props} key={option.value}>
                    //     {option.label}
                    // </li>
                    const isActive = hoveredOption?.value === option.value;
                    const isSelected = selected?.value === option.value;

                    return (
                        <li
                            {...props}
                            key={option.value}
                            onMouseEnter={() => setHoveredOption(option)} // Track mouse hover
                            onMouseLeave={() => setHoveredOption(null)} // Reset hover state
                            className={clsx(
                                "relative cursor-default select-none py-2 px-4",
                                isSelected && "font-medium", // Option is selected
                                isActive && "bg-surface-accent" // Option is active (highlighted/hovered)
                            )}
                        >
                            {option.label}
                        </li>
                    );
                }}
            />
        </div>
    );
};

export default FormAutocomplete;
