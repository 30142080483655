import { Box, MenuItem, Select } from "@mui/material";
import FormInputLabel from "../FormInputLabel";
import { Controller, UseFormReturn } from "react-hook-form";
import { talentTooltips } from "../../tooltipsContent";
import { useAgencies } from "../../api/agencies";
import { useOrganizationConfig } from "../../api/organization";
import { TalentEditFormValues } from "./TalentEditFormValues";

export type TalentAgencyFormDropdownProps = {
    methods: UseFormReturn<TalentEditFormValues>
    isSubmitting: boolean
}
export const TalentAgencyFormDropdown = ({
    methods,
    isSubmitting,
}: TalentAgencyFormDropdownProps) => {
    const { agencies, isLoading } = useAgencies();
    const { talentTerminology } = useOrganizationConfig();

    const agencyOptions = agencies.map((agency) => ({ value: agency.id, label: agency.name }));

    return (
        <Box className="flex flex-col space-y-2">
            <FormInputLabel tooltipText={talentTooltips.talentAgency(talentTerminology)}>Agency</FormInputLabel>
            {isLoading ? "Loading..." : (
                <Controller
                    name="agencyId"
                    control={methods.control}
                    render={({ field: { onChange, value } }) => (
                        <Select
                            value={value}
                            onChange={onChange}
                            variant="outlined"
                            color="secondary"
                            disabled={isSubmitting || isLoading}
                        >
                            <MenuItem value="None">None</MenuItem>
                            {
                                agencyOptions.length > 0 && agencyOptions.map(option => (
                                    <MenuItem key={option?.value} value={option?.value}>{option?.label}</MenuItem>
                                ))
                            }
                        </Select>
                    )}
                />
            )}
        </Box>
    );
};