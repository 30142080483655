import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { Box, Button, MenuItem, Select } from "@mui/material";
import { Controller, FormProvider, useFieldArray } from "react-hook-form";
import { deliveryTypeOptions, expectedDurationOptions, GigDetails } from "../../api/models/gig";
import { useOrganizationConfig } from "../../api/organization";
import FormCard from "../FormCard";
import FormInputLabel from "../FormInputLabel";
import FormTextInput from "../FormTextInput";
import FormTextAreaInput from "../FormTextAreaInput";
import ToggleButton from "../ToggleButton";
import GigSkillsInput from "../GigSkillsInput";
import { GigEditFormValues, useEditGigForm } from "./GigEditFormValues";
import Loader from "../Loader";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import ConfirmDialogBox from "../ConfirmDialogBox";
import useConfirmDialogBoxState from "../ConfirmDialogBox/useConfirmDialogBoxState";
import FormErrors from "../FormErrors";
import ApiError from "../../api/common/apiError";
import IndustrialExperienceDropdown from "../IndustrialExperienceDropdown";
import FormDropdown, { FormDropdownOption } from "../FormDropdown";
import { fetchLocalities, fetchLocationOptions } from "../../api/location";
import useAccessToken from "../../auth/useAccessToken";
import LanguageDropdown from "../LanguageDropdown";
import FormDateTimeInput from "../FormDateTimeInput";
import FormAutocomplete from "../FormAutocomplete";
import useSearchLocalities from "../../utils/useSearchLocalities";
import GigTaggedUserInput from "../GigTaggedUserInput";

export type GigEditFormProps = {
    gig: GigDetails
    loading: boolean
    submissionError?: ApiError
    handleSaveChanges: (values: GigEditFormValues) => Promise<void>
    handleCloseEditGigPage: () => void
}

const GigEditForm = ({
    gig,
    loading,
    submissionError,
    handleSaveChanges,
    handleCloseEditGigPage
}: GigEditFormProps) => {
    const accessToken = useAccessToken();
    const { gigTerminology } = useOrganizationConfig();
    const methods = useEditGigForm(gig);
    const isLargeScreen = useIsLargeScreen();
    const { control, reset, watch, handleSubmit, formState, formState: { dirtyFields } } = methods;
    const isDirtyAlt = Object.keys(dirtyFields).length > 0;
    const [allLocationOptions, setAllLocationOptions] = useState<FormDropdownOption<string>[]>([]);
    const [isLoadingLocationOptions, setIsLoadingLocationOptions] = useState(false);

    const [selectedLocationLocalities, setSelectedLocationLocalities] = useState<FormDropdownOption<string>[]>([]);
    const [openCancelDialog, cancelDialogState] = useConfirmDialogBoxState({
        onConfirm: () => {
            handleCloseEditGigPage();
        }
    });
    const handleDiscardEditGig = () => {
        if (isDirtyAlt) {
            openCancelDialog();
        } else {
            handleCloseEditGigPage();
        }
    };
    const { append: appendSkill, remove: removeSkill } = useFieldArray<GigEditFormValues, "skills">({
        control: control,
        name: "skills",
        keyName: "id",
    });

    const { append: appendTagged, remove: removeTagged } = useFieldArray<GigEditFormValues, "gigTaggedUsers">({
        control: control,
        name: "gigTaggedUsers",
        keyName: "id",
    });

    const { skills, gigTaggedUsers  } = watch();

    useEffect(() => {
        if (!gig) return;
        reset({
            ...gig,
            localityId: gig?.location?.locality?.localityId || "",
            locationId: gig?.location?.countryId || "",
        });
        if (gig?.location?.locality) {
            const localities = [
                {
                    value: gig.location.locality.localityId,
                    label: `${gig.location.locality.locality}${
                        gig.location.locality.region ? ` (${gig.location.locality.region})` : ""
                    }`,
                },
            ];
            setSelectedLocationLocalities(localities);
        }
    }, [gig, reset]);
    
    useEffect(() => {
        const locationId = methods.watch("locationId");
    
        if (locationId && locationId !== gig?.location?.countryId) {
            console.log("Fetching localities for updated location:", locationId);
            fetchAndSetLocalities(locationId);
        }
    }, [methods.watch("locationId"), accessToken, gig]);
    
    const fetchAndSetLocalities = (locationId: string) => {
        fetchLocalities(locationId, accessToken, "", 0, 2500)
            .then((response) => {
                const localities = response.items.map((locality) => ({
                    value: locality.localityId,
                    label: `${locality.locality}${locality.region ? ` (${locality.region})` : ""}`,
                }));
                setSelectedLocationLocalities(localities);
                methods.setValue("localityId", "");
            })
            .catch((error) => {
                console.error("Error fetching localities:", error);
            });
    };
    
    useEffect(() => {
        const selectedLocationId = methods.watch("locationId");
        if (!selectedLocationId) {
            methods.setValue("localityId", ""); // Clear locality when no location selected
        }
    }, [methods.watch("locationId")]);
    
    useEffect(() => {
        if (accessToken) {
            const loadAllLocations = async () => {
                setIsLoadingLocationOptions(true);
                try {
                    const fetchedLocationOptions = await fetchLocationOptions("", accessToken);
                    setAllLocationOptions(fetchedLocationOptions as FormDropdownOption<string>[]);
                } finally {
                    setIsLoadingLocationOptions(false);
                }
            };
            loadAllLocations();
        }
    }, [accessToken]);            

    const isDesignFeeRequired = methods.watch("isDesignFeeTypeRequired");
    const isConsultancyFeeRequired = methods.watch("isConsultancyFeeTypeRequired");
    const isPrepAndEvalFeeReqired = methods.watch("isPrepAndEvalFeeTypeRequired");

    useEffect(function resetFeeTypeFieldsWhenFeeIsFalse() {
        if (!isDesignFeeRequired) methods.setValue("designFeeTypeHours", undefined);
        if (!isConsultancyFeeRequired) methods.setValue("consultancyFeeTypeHours", undefined);
        if (!isPrepAndEvalFeeReqired) methods.setValue("prepAndEvalFeeTypeHours", undefined);
    }, [isDesignFeeRequired, isConsultancyFeeRequired, isPrepAndEvalFeeReqired]);
    const selectedLocationId = methods.watch("locationId") || "";

    useEffect(function resetLocalityOnLocationChange() {
        if (selectedLocationLocalities.length < 1 || isLoadingLocationOptions) return;
        methods.setValue("localityId", "");
    }, [selectedLocationId]);
    
    useEffect(function setLocationOptions() {
        if (accessToken) {
            const loadAllLocations = async () => {
                setIsLoadingLocationOptions(true);
                try {
                    const fetchedLocationOptions = await fetchLocationOptions("", accessToken);
                    setAllLocationOptions(fetchedLocationOptions as FormDropdownOption<string>[]);
                } finally {
                    setIsLoadingLocationOptions(false);
                }
            };
            loadAllLocations();
        }
    }, [accessToken]);
    const {
        localities,
        handleSearchChange,
    } = useSearchLocalities(fetchLocalities, selectedLocationId, accessToken);

    useEffect(() => {
        if (localities.length > 0) {
            const updatedLocalities = localities.map((locality) => ({
                value: locality.localityId,
                label: `${locality.locality}${locality.region ? ` (${locality.region})` : ""}`,
            }));
            setSelectedLocationLocalities(updatedLocalities);
        }
    }, [localities]);
    if (!gig) return <Loader />;

    return (
        <>
            <FormProvider {...methods}>
                <form
                    onSubmit={handleSubmit(handleSaveChanges)}
                    noValidate
                >
                    <FormCard
                        title={`Edit ${gigTerminology}`}
                    >
                        <Box className="space-y-4">
                            <Box className="space-y-2">
                                <FormInputLabel required>{"Client name"}</FormInputLabel>
                                <FormTextInput
                                    required
                                    name="title"
                                />
                            </Box>
                            <GigSkillsInput
                                addSkill={appendSkill}
                                removeSkill={removeSkill}
                                skills={skills}
                                error={formState.errors.skills}
                                showError={formState.submitCount > 0}
                                required
                            />
                            <Box className="space-y-2">
                                <FormInputLabel required>{"Select industry of the client"}</FormInputLabel>
                                <Controller
                                    name="industryId"
                                    control={methods.control}
                                    render={({ field: { onChange, value } }) => (
                                        <IndustrialExperienceDropdown
                                            required
                                            error={methods.formState.errors.industryId}
                                            onChange={onChange}
                                            value={value || ""}
                                        />
                                    )}
                                />
                            </Box>
                            <Controller
                                name="deliveryTypeId"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <FormDropdown
                                        required
                                        label="What is the required delivery format for this event?"
                                        options={deliveryTypeOptions}
                                        error={methods.formState.errors.deliveryTypeId}
                                        onChange={onChange}
                                        value={value}
                                    />
                                )}
                            />
                            <Box className="space-y-2">
                                <FormInputLabel required>{`Language this ${gigTerminology.toLowerCase()} is to be delivered in`}</FormInputLabel>
                                <Controller
                                    name="languageId"
                                    control={methods.control}
                                    render={({ field: { onChange, value } }) => (
                                        <LanguageDropdown
                                            required
                                            error={methods.formState.errors.languageId}
                                            onChange={onChange}
                                            value={value || ""}
                                        />
                                    )}
                                />
                            </Box>
                            <Box className="space-y-2">
                                <FormInputLabel required>What are the expected number of participants?</FormInputLabel>
                                <FormTextInput
                                    name="expectedNumberParticipants"
                                    required
                                    type="number"
                                    placeholder="Number of particpants"
                                    inputProps={{ min: 0, max: 100, step: 1 }}
                                />
                            </Box>
                            {allLocationOptions?.length > 0 ? (
                                <Controller
                                    name="locationId"
                                    control={methods.control}
                                    render={({ field: { onChange, value } }) => (
                                        <FormAutocomplete
                                            required
                                            label="What country is the client located in?"
                                            value={value || ""}
                                            options={allLocationOptions}
                                            disabled={isLoadingLocationOptions}
                                            onChange={onChange}
                                            error={methods.formState.errors.locationId}
                                            isLoadingOptions={allLocationOptions.length < 1 || isLoadingLocationOptions}
                                        />
                                    )}
                                />
                            ) : <div>Loading...</div>}
                            {selectedLocationLocalities?.length > 0 && (
                                <Controller
                                    name="localityId"
                                    control={methods.control}
                                    render={({ field: { onChange } }) => (
                                        <FormAutocomplete
                                            required
                                            label="City"
                                            value={methods.watch("localityId") || ""}
                                            options={selectedLocationLocalities}
                                            disabled={selectedLocationLocalities.length < 1 || isLoadingLocationOptions}
                                            onChange={onChange}
                                            onInputChange={(event) => {
                                                handleSearchChange(event || ""); // Update localities based on input change
                                            }}
                                            error={methods.formState.errors.localityId}
                                            isLoadingOptions={isLoadingLocationOptions}
                                        />
                                    )}
                                />
                            )}
                            <FormTextAreaInput
                                required={false}
                                name="address"
                                label="Address"
                                placeholder="Please enter the exact address of the event, if available"
                            />
                            <Box className="flex flex-col flex-1">
                                <Controller
                                    name="startDate"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <FormDateTimeInput
                                            name="startDate"
                                            label="Start date"
                                            value={value}
                                            onChange={onChange}
                                            minDate={new Date()}
                                            required
                                        />
                                    )}
                                />
                            </Box>
                            <Box className="flex flex-col flex-1 space-y-2">
                                <FormInputLabel required>Duration</FormInputLabel>
                                <Controller
                                    name="expectedDurationId"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <Select
                                            value={value}
                                            onChange={onChange}
                                            variant="outlined"
                                            color="secondary"
                                        >
                                            {expectedDurationOptions.map(option => (
                                                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                            </Box>
                            <FormTextAreaInput
                                name="description"
                                label={"Program objectives"}
                                placeholder="Add any relevant objectives"
                            />
                            <Controller
                                name="isDesignFeeTypeRequired"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <ToggleButton
                                        label="Does the event require customisation of content and/or addition of new content?"
                                        onChange={onChange}
                                        checked={value}
                                    />
                                )}
                            />
                            {isDesignFeeRequired == true && (
                                <FormTextInput
                                    required={isDesignFeeRequired}
                                    label="No. of design hours"
                                    placeholder="Number of hours"
                                    type="number"
                                    onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                                    {...methods.register("designFeeTypeHours")}
                                />
                            )}
                            <Controller
                                name="isConsultancyFeeTypeRequired"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <ToggleButton
                                        label="Does the event require time spent using consultancy expertise to assist the client in achieveing the value or impact required?"
                                        onChange={onChange}
                                        checked={value}
                                    />
                                )}
                            />
                            {isConsultancyFeeRequired == true && (
                                <FormTextInput
                                    required={isConsultancyFeeRequired}
                                    label="No. of consultancy hours"
                                    placeholder="Number of hours"
                                    type="number"
                                    onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                                    {...methods.register("consultancyFeeTypeHours")}
                                />
                            )}
                            <Controller
                                name="isPrepAndEvalFeeTypeRequired"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <ToggleButton
                                        label="Does the event require additional time spent in preparation or evaluation to achieve a successful outcome? (prep and eval)?"
                                        onChange={onChange}
                                        checked={value}
                                    />

                                )}
                            />
                            {isPrepAndEvalFeeReqired == true && (
                                <FormTextInput
                                    required={isPrepAndEvalFeeReqired}
                                    label="No. of prep and eval hours"
                                    placeholder="Number of hours"
                                    type="number"
                                    onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                                    {...methods.register("prepAndEvalFeeTypeHours")}
                                />
                            )}
                            <GigTaggedUserInput
                                addTaggedUser={appendTagged}
                                removeTaggedUser={removeTagged}
                                gigTaggedUsers={gigTaggedUsers}
                                error={formState.errors.gigTaggedUsers}
                                showError={formState.submitCount > 0}
                                required
                                giggedClientId={gig.giggedClientId}
                            />                            
                            <Box className="space-y-2">
                                <FormInputLabel>Enter opportunity link</FormInputLabel>
                                <FormTextInput
                                    name="opportunityLink"
                                    placeholder="Please provide the URL from D365"
                                />
                            </Box>
                            <FormTextAreaInput
                                name="notes"
                                label="Notes"
                                placeholder="Add any relevant notes"
                                rows={7}
                            />
                            <Controller
                                name="hasClientHistory"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <ToggleButton
                                        label={"Have we worked with this client before?"}
                                        onChange={onChange}
                                        checked={value}
                                    />
                                )}
                            />
                            <Controller
                                name="isAudienceExecutive"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <ToggleButton
                                        label={"Is the audience Senior Executive/C-Suite level?"}
                                        onChange={onChange}
                                        checked={value}
                                    />
                                )}
                            />
                            <FormErrors messages={submissionError?.userMessages} />

                            <Box className="flex flex-col-reverse md:flex-row md:justify-between md:items-center !mt-8">
                                <Button
                                    color="secondary"
                                    variant="text"
                                    disabled={loading}
                                    onClick={handleDiscardEditGig}
                                >
                                    Discard changes
                                </Button>
                                <LoadingButton
                                    variant="contained"
                                    startIcon={<SaveOutlinedIcon sx={!isDirtyAlt || loading ? { opacity: "26%" } : { opacity: "100%" }} />}
                                    type="submit"
                                    color="primary"
                                    loading={loading}
                                    disabled={!isDirtyAlt}
                                    sx={!isLargeScreen ? { marginBottom: 2 } : { marginBottom: 0 }}
                                >
                                    Save changes
                                </LoadingButton>
                            </Box>
                        </Box>
                    </FormCard>
                </form>
            </FormProvider>
            <ConfirmDialogBox
                {...cancelDialogState}
                message="Discard unsaved changes?"
                confirmButtonText="Discard"
            />
        </>
    );
};

export default GigEditForm;