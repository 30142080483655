import { ApiAgencyDto } from "./api/agencies";
import { ApiBadgeResponse } from "./api/badge";
import { LanguageDto } from "./api/language";
import { LocalityDto, LocationDto } from "./api/location";
import { WheelPositionDto } from "./api/wheelPosition";

export type TalentSummaryDto = {
    id: string
    firstName: string
    lastName: string
    tradingName?: string
    email: string
    createdAt: string
    profileImageThumbnailUrl?: string
    skills: TalentSkillDto[]
    ongoingGigsCount: number
    completedGigsCount: number
    cancelledGigsCount: number
    profileHealthScore: number
    organizationAvailability?: string
    agencyName?: string
    isQualiopiRequired: boolean
    genderTypeId?: number
    vendorName?: string
    phoneNumber?: string
    insuranceExpiryDate?: string
    contractSentDate?: Date | null
    competencyLevel?: TalentCompetencyLevelDto    
    wheelPosition?: WheelPositionDto
    documentationLanguage?: LanguageDto
    gigCompletedHours: number
    isHidden: boolean
}

export type TalentSkillDto = {
    skillId: string;
    skillName: string;
    createdAt: string
}

export type TalentDetailsDto = {
    id: string
    firstName: string
    lastName: string
    organizationAvailability?: string
    agency?: ApiAgencyDto
    badges?: ApiBadgeResponse[]
    isQualiopiRequired: boolean
    vendorName?: string | null
    presentationStyle?: string | null
    phoneNumber?: string
    email: string
    insuranceExpiryDate: Date | null
    contractSentDate: Date | null
    ratePack?: TalentRatePackDto
    competencyLevel?: TalentCompetencyLevelDto
    wheelPosition?: WheelPositionDto
    documentationLanguage?: LanguageDto
    knownCompetitors?: string | null
    notes?: string | null
    rlc?: string | null
    location?: ApiTalentLocationDto
    languages?: ApiTalentLanguagesDto[]
    professionalBackground?: string | null
    gigCompletedHours: number
    skills: TalentSkillDto[]
    isHidden: boolean
}

export type UpdateTalentDto = {
    organizationAvailability?: string | null
    agencyId?: string | null
    isQualiopiRequired: boolean
    vendorName?: string | null
    contractSentDate: Date | null
    insuranceExpiryDate: Date | null
    presentationStyle?: string | null
    knownCompetitors?: string | null
    notes?: string | null
    rlc?: string | null
    professionalBackground?: string | null
    locationId: string | null
    localityId: string | null
    isHidden: boolean
}

export type AddTalentBadgeDto = {
    badgeId: string
}

export type DeleteTalentBadgeDto = AddTalentBadgeDto;

export type TalentRatePackDto = {
    id: string
    name: string
}

export type TalentCompetencyLevelDto = {
    id: string
    name: string
}

export type ApiTalentLocationDto = Omit<LocationDto, "id" | "localities"> & {
    countryId: string
    locality: LocalityDto 
}

export type ApiTalentLanguagesDto = {
    languageId: string,
    languageName: string,
    languageProficiencyId: number,
    languageProficiencyName: string,
    createdAt: string
}

export type AdminAddTalentLanguageDto = {
    languageProficiencyId: number
}

export enum GenderType {
    Female = 0,
    Male = 1, 
    Other = 2, 
    PreferNotToSay = 3
}

export const genderTypeOptions: { [id in GenderType]: string } = {
    [GenderType.Female]: "Female",
    [GenderType.Male]: "Male",
    [GenderType.Other]: "Other",
    [GenderType.PreferNotToSay]: "Prefer not to say",
};

export const languageProficiencyOptions = [
    { label: "Beginner", value: 0 },
    { label: "Intermediate", value: 1 },
    { label: "Proficient", value: 2 },
    { label: "Fluent", value: 3 },
    { label: "Native", value: 4 },
];